





























































import Vue from 'vue'
import { UserReportInterface } from '@/types/models/UserReportInterface'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import ReportsService from '@/services/reports'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'

export default Vue.extend({
  name: 'ReportsPage',
  components: { DebounceSearchComponent },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    headers: [
      { text: 'From', value: 'reporter.username', sortable: false },
      { text: 'User', value: 'offender.username', sortable: false },
      { text: 'Reason', value: 'reason', sortable: false, cellClass: 'report-reason' },
      { text: 'Screen', value: 'screen', sortable: false, cellClass: 'report-screenshot' },
      { text: 'Created', value: 'created', sortable: false },
      { text: 'Modified', value: 'modified', sortable: false },
      { text: 'Resolved', value: 'unread', sortable: false }
    ],
    list: [] as UserReportInterface[],
    dialog: false,
    dialogImg: null as string | null
  }),
  methods: {
    getDataFromAPI () {
      const filters = this.prepareFilters()
      ReportsService.get(this.tableOptions, filters)
        .then(res => {
          if (res.data.body) {
            this.list = res.data.body.items ? res.data.body.items : []
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 1
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    resolve (id: number) {
      ReportsService.resolve(id)
        .then(res => {
          if (res.data.body) {
            const index = this.list.findIndex(i => i.id === id)
            if (index > -1 && this.list[index]) {
              this.list[index].unread = false
              if (res.data.body.report && res.data.body.report.modified) {
                this.list[index].modified = res.data.body.report.modified
              }
            }
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    showImageModal (url: string) {
      this.dialogImg = url
      this.dialog = true
    }
  }
})
